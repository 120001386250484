@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #3B82F6 #FFFFFF;
  }
  
  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    width: 4px;
    width: 4px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-track:hover {
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-track:active {
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #3B82F6;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #3B82F6;
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #3B82F6;
  }
  
  